/* section2.css */
.section2 {
  color: white;
  padding: 50px 0;
  transform: translateY(-130px);

}


.discord-title {
  font-size: 72px; /* Adjust the size as needed */
  font-weight: bolder;
  margin-bottom: 24px; /* Adjust spacing as needed */
  color: #fff;
}

.discord-subtitle {
  font-size: 18px; /* Adjust the size as needed */
  margin-bottom: 32px; /* Adjust spacing as needed */
  color: #9b9ca1;
  
}

.section2 .feature-card:hover {
  transform: translateY(-5px); /* Lift the card slightly on hover */
  box-shadow: /* Neon glow effect */
    0 0 10px #7470f0, /* Outer glow */
    0 0 20px #502be2, /* Medium glow */
    0 0 30px #4d1fa2, /* Inner glow */
    0 0 40px #4c1b9a; /* Additional color glow */
}
.btn {background:none !important;}

.discord-icon {
  max-width: 60%;
  height: auto;
  border-radius: 10px; /* Match the border-radius of the card */
}

@media (max-width: 768px) {
  .discord-title {
    font-size: 36px; /* Smaller font size for mobile */
    margin-bottom: 16px; /* Smaller margin for mobile */
  }

  .discord-subtitle {
    font-size: 16px; /* Smaller font size for mobile */
    margin-bottom: 20px; /* Smaller margin for mobile */
  }

  .discord-icon {
    max-width: 100%; /* Larger width for mobile to use available space */
    margin-top: 20px; /* Space above the icon on mobile */
  }


  /* Adjust the button styles for mobile */
  .btn {
    width: 100%; /* Full width buttons are easier to tap on mobile */
    padding: 10px; /* Larger padding for a bigger tap area */
  }

  /* Adjust card padding and margin for mobile */
  .section2 .feature-card {
    padding: 15px; /* Smaller padding inside the card for mobile */
    margin-bottom: 20px; /* Less space between cards on mobile */
  }
}
