/* Indicators.css */
.indicators-container {
    padding: 50px 0;
    background: radial-gradient(#304c9f, #2b2f48 60%);
    transform: translateY(-130px);

    color: white;
    text-align: center;
}

.indicators-header {
    font-size: 72px;
    font-weight: bolder;
    margin-bottom: 24px;
}

.indicators-subtitle {
    font-size: 18px;
    margin-bottom: 32px;
    color: #9b9ca1;
}

.indicator-card {
    display: flex;
    flex-direction: column;
    background-color: #2a3042;
    border-radius: 10px;
    margin-bottom: 30px;
    height: 400px; /* Adjust as necessary for your design */
    overflow: hidden;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.indicator-card:hover {
    transform: translateY(10px);
    box-shadow: 
        0 0 10px #7470f0,
        0 0 20px #502be2,
        0 0 30px #4d1fa2,
        0 0 40px #4c1b9a;
}

.card-title {
    font-size: 1.5rem;
    color: #58a6ff;
    margin-top: 1rem;
}

.card-content { /* Container for text content with scroll */
    flex-grow: 1;
    height: calc(100% - 160px);
    overflow-y: auto; /* Scrollbar will appear if content overflows */
    margin-bottom: 10px; /* Space between text and any content below */
}

.card-text {
    font-size: 1rem;
    color: #c9d1d9;
}

.algo-indicator-img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px; /* Space between image and text content */
}

/* Button styling */
.indicator-card .btn {
    background-color: #6366f1;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    align-self: center;
}

.indicator-card .btn:hover {
    background-color: #4446e8;
}


.slick-slide {
    padding: 0 15px; /* Add horizontal padding */
}
.slick-track {
    display: flex;
    align-items: stretch; /* This will make all children (slides) of the flex container the same height */
}

.card-title {
    font-size: 1.5rem;
    color: #58a6ff;
    margin-top: 1rem;
}

.card-text {
    font-size: 1rem;
    color: #c9d1d9;
    margin-bottom: 2rem;
    flex-grow: 1; /* Added to make the text container grow and push the image down */
}


  .card-title {
    font-size: 1.5rem;
    color: #58a6ff;
    margin-top: 1rem;
  }
  
  .card-text {
    font-size: 1rem;
    color: #c9d1d9;
    margin-bottom: 2rem;
  }
  
  

  .card-content {
    flex: 1; /* Grow to use available space, pushing the image down */
    display: flex; /* Make this a flex container */
    flex-direction: column; /* Arrange children vertically */
    justify-content: space-between; /* Space between items */
}

  
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    z-index: 1050; /* Above most other items */
  }
  
  .custom-modal-content {
    max-width: 90%; /* Adjust the modal width as a percentage of the screen size */
    max-height: 80vh; /* Limit the image's height to not exceed 80% of the viewport height */
  }
  
  .custom-modal-image {
    max-width: 100%; /* Limit the image to not exceed the modal's width */
    max-height: 80vh; /* Limit the image's height to not exceed 80% of the viewport height */
    height: auto; /* Maintain the image's aspect ratio */
    object-fit: contain; /* Ensures the image is fully visible without being cropped */
  }
  
  
  /* Close the modal when clicked on the overlay */
  .custom-modal:not(.custom-modal-body) {
    cursor: pointer;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .indicators-header {
      font-size: 2rem;
    }
  
    .indicators-subtitle {
      font-size: 1rem;
    }
  
    .card-title {
      font-size: 1.25rem;
    }
    .card-text {
        overflow-y: auto; /* Maintain scroll on mobile if needed */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    }
    .algo-indicator-img {
      margin-top: 20px;
    }
  }
  