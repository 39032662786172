/* CustomStyles.css */
.crypto-input-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.crypto-input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid #ced4da; /* Bootstrap's default border color for inputs */
    font-size: 1rem;
    color: #495057; /* Bootstrap's default text color for inputs */
}

.crypto-input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.submit-button {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.submit-button:hover {
    background-color: #0056b3; /* Darken the button on hover */
}


.gradient-text-pricing {
    font-size: 40px; /* Adjust the font size as needed */
    font-weight: bold; /* A bold font weight can make the gradient more prominent */
    background: linear-gradient(45deg, #0066ff, #d6d6d6); /* Define your gradient */
    
    /* Fallback color if gradients are not supported */
    color: #0066ff; /* Fallback color - use the starting color of your gradient */
    
    /* Create the text fill effect for browsers that support these properties */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
    /* Standard property for text fill color, though not widely supported */
    text-fill-color: transparent; 
  }
  

