/* footer.css */
.footer-hero {
    background-color: #222736; /* Dark background */
    
    color: white; /* White text */
    padding: 40px 0; /* Padding top and bottom */
  }
  
  .footer-hero h5 {
    color: #f0cc52; /* Highlighted color for headings */
    margin-bottom: 15px; /* Spacing after the heading */
  }
  
  .footer-hero p {
    color: #9b9ca1; /* Subtle color for text */
    margin-bottom: 15px; /* Spacing after paragraphs */
  }

  .footer-hero img {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
     
}
  
  .footer-hero ul {
    padding: 0;
    list-style: none; /* Remove default list style */
  }
  
  .footer-hero ul li a {
    color: #9b9ca1; /* Subtle color for links */
    text-decoration: none; /* No underline */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .footer-hero ul li a:hover {
    color: #f0cc52; /* Highlight color on hover */
  }
  
  .footer-bottom {
    margin-top: 20px; /* Space between main footer content and bottom row */
    padding-top: 10px; /* Padding top for bottom row */
    border-top: 1px solid #333; /* Subtle top border */
  }
  
  .footer-bottom p {
    color: #9b9ca1; /* Subtle color for bottom text */
    margin-bottom: 0; /* Remove default margin */
  }
  
  /* Add responsive adjustments if necessary */
  @media (max-width: 768px) {
    .footer-hero {
      text-align: center; /* Center align text for mobile */
    }
  
    .footer-hero .row {
      margin-bottom: 20px; /* Space between rows */
    }
  }
  