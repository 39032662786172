body{    
  background: linear-gradient(#304c9f, #2b2f48 16.19%);
}
/* HeroSection.css */
.hero-section {
    color: white;
    padding: 50px 0;
      transform: translateY(-80px);
  
      
  }
  
  .hero-title .new-line {
    display: block;
  }
  
  
  .hero-title {
    font-size: 72px; /* Adjust the size as needed */
    font-weight: bolder;
    margin-bottom: 24px; /* Adjust spacing as needed */
  }
  
  .hero-subtitle {
    font-size: 18px; /* Adjust the size as needed */
    margin-bottom: 32px; /* Adjust spacing as needed */
    color: #9b9ca1;
    
  }
  
  .button-group .btn-get-started {
    background-color: #6366f1 !important; /* Adjust to match your design */
    color: white;
    border: none;
    margin-right: 15px; /* Spacing between buttons */
  }
  
  .button-group .btn-read-docs {
    border-color: white; /* Adjust to match your design */
    color: white;
  }
  
  .button-group .btn-get-started:hover {
    background-color: #4446e8 !important; /* Adjust hover state as needed */
  }
  
  .button-group .btn-read-docs:hover {
    background-color: white; /* Adjust hover state as needed */
    color: #6366f1; /* Adjust text color on hover as needed */
  }
  
  .hero-graphic {
    max-width: 100%;
    height: auto;
  }
  
  .hero-section hr {
    border: 0;
    height: 0.3px; /* Thickness of the HR */
    background-color: #f0cc52; /* Color of the HR */
    margin-top: 70px; /* Space above the HR */
    margin-bottom: 20px; /* Space below the HR */
    width: 70%; /* Width of the HR */
    margin-left: auto; /* Centers the HR */
    margin-right: auto; /* Centers the HR */
  }