.page-content {
    padding-top: 20px;
  }
  
  .card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    margin-bottom: 20px;
    font-weight: bold;
  }
  