/* nfts-section.css */
.nfts-container {
    color: white;
    padding: 50px 0;
    transform: translateY(-130px);
  }
  
  .nfts-title {
    font-size: 72px;
    font-weight: bolder;
    margin-bottom: 24px;
    color: #fff;
  }
  
  .nfts-subtitle {
    font-size: 18px;
    margin-bottom: 32px;
    color: #9b9ca1;
  }
  
  .nfts-container .feature-card:hover {
    transform: translateY(-5px);
    box-shadow:
      0 0 10px #7470f0,
      0 0 20px #502be2,
      0 0 30px #4d1fa2,
      0 0 40px #4c1b9a;
  }
  
  .nfts-icon {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .nfts-title {
      font-size: 36px;
      margin-bottom: 16px;
    }
  
    .nfts-subtitle {
      font-size: 16px;
      margin-bottom: 20px;
    }
  
    .nfts-icon {
      max-width: 100%;
      margin-top: 20px;
    }
  
    .btn {
      width: 100%;
      padding: 10px;
    }
  
    .nfts-container .feature-card {
      padding: 15px;
      margin-bottom: 20px;
    }
  }
  