/* FeatureCards.css */
.feature-section {
    background: radial-gradient(#304c9f, #2b2f48 60%);
    color: white;
    transform: translateY(-130px);
  }
  
  .feature-section h2 {
    margin-bottom: 20px;
    color: #fff; /* Title color */
  }
  
  .feature-section p {
    margin-bottom: 40px;
    color: #9b9ca1; /* Subtitle color */
  }
  
  .feature-card {
    background-color: #222736; /* Card background color */
    color: #fff; /* Card text color */
    border: none;
    border-radius: 10px; /* Adjust as needed */
    padding: 20px;
    margin-bottom: 30px; /* Spacing between cards */
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
  .feature-card h5 {
    margin-top: 20px;
    color: #fff; /* Card title color */
  }
  
  .feature-card p {
    color: #fff; /* Card description color */
  }
  
  /* Neon glow effect on card hover */
  .feature-card:hover {
    transform: translateY(-5px); /* Slightly raise the card on hover */
    box-shadow:
      0 0 10px #7470f0, /* Outer glow */
      0 0 20px #502be2, /* Medium glow */
      0 0 30px #4d1fa2, /* Inner glow */
      0 0 40px #4c1b9a; /* Additional color glow */
  }
  
  /* Icon styles */
  .icons-api-card {
    font-family: 'Font Awesome 5 Free'; /* Specify the icon font family */
    font-weight: 900; /* Required for solid icons */
    transition: text-shadow 0.3s ease; /* Smooth transition for text-shadow */
    /* Add additional styling for your icon here */
  }
  
  /* Neon glow effect on icon when card is hovered */
  .feature-card:hover .icons-api-card {
    text-shadow:
      0 0 5px #7470f0, /* Outer glow */
      0 0 10px #502be2, /* Medium glow */
      0 0 15px #4d1fa2, /* Inner glow */
      0 0 20px #4c1b9a; /* Additional color glow */
  }
  

/* Mobile specific styles */
@media (max-width: 768px) {
  .feature-section {
    transform: translateY(-30px);
    padding: 30px 0; /* Adjust padding for smaller screens */
  }

  .feature-section h2 {
    font-size: 24px; /* Smaller font size for mobile */
    margin-bottom: 15px; /* Less space under the title */
  }

  .feature-section p {
    font-size: 16px; /* Smaller font size for mobile */
    margin-bottom: 20px; /* Less space under the subtitle */
  }

  .feature-card {
    padding: 15px; /* Less padding inside the cards */
    margin-bottom: 20px; /* Less space between cards */
  }

  .feature-card h5 {
    font-size: 18px; /* Smaller font size for card titles */
    margin-top: 15px; /* Adjust top margin for the title */
  }

  .feature-card p {
    font-size: 14px; /* Smaller font size for card text */
  }

  .icons-api-card {
    /* Adjust icon size if necessary */
  }

  /* Adjust neon glow effect on hover if necessary, or remove for mobile to prevent performance issues */
  .feature-card:hover .icons-api-card {
    text-shadow: none; /* Remove text-shadow on hover for mobile devices */
  }
}

