/* pricing.css */
.pricing-container {
    background: radial-gradient(#304c9f, #2b2f48 60%);
    transform: translateY(-130px);
    padding: 50px 0;
    text-align: center; /* Center align the text within the container */
    color: white; /* Set text color to white to match the hero section */
  }
  

  .pricing-header {
    font-size: 72px; /* Adjust the size as needed */
    font-weight: bolder;
    margin-bottom: 24px; 
    color: #fff; /* Adjust to match your design */
  }
  
  .pricing-subtitle {
    font-size: 18px; /* Adjust the size as needed */
    margin-bottom: 32px; /* Adjust spacing as needed */
    color: #9b9ca1;
  }
  
  .pricing-card {
    background-color: #222736; /* Dark background for the card, similar to the hero section */
    color: white; /* White text for consistency */
    border-radius: 15px; /* Rounded corners for a modern look */
    margin: 15px 0;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    border-top: 5px solid; /* Placeholder for the colored top border */
  }
  
  .pricing-card:hover {
    transform: translateY(-10px); 
    box-shadow: /* Neon glow effect */
    0 0 10px #7470f0, /* Outer glow */
    0 0 20px #502be2, /* Medium glow */
    0 0 30px #4d1fa2, /* Inner glow */
    0 0 40px #4c1b9a; /* Additional color glow *//* Raise the card on hover for interactivity */
  }
  
  .pricing-card h3 {
    color: #fff; /* Color to match the header */
    font-size: 1.75rem; /* Size to make it stand out but not overpower the header */
  }
  
  .pricing-card h2 {
    font-size: 2.5rem; /* A larger size for price for emphasis */
    color: #f0cc52; /* A bright color for price to attract attention */
  }
  
  .features-list {
    list-style: none; /* Remove default list styling */
    padding: 0;
    margin: 20px 0;
  }
  
  .features-list li {
    margin: 10px 0;
    color: #9b9ca1; /* Color to match the hero section subtitle */
  }
  
  /* Specific styles for each plan's top border */
  .pricing-card.essential {
    border-top-color: #007bff; /* Blue border for Essential */
  }
  
  .pricing-card.premium {
    border-top-color: #ffc107; /* Yellow border for Premium */
    background: #f8f9fa; /* Light background for contrast */
    z-index: 1; /* Ensure it's above other elements */
  }
  
  .pricing-card.enterprise {
    border-top-color: #28a745; /* Green border for Enterprise */
  }
  

  
 .card {
    background: #3e4969 !important;
}

/* Mobile specific styles */
@media (max-width: 768px) {
    .pricing-header {
      font-size: 36px; /* Smaller font size for mobile */
    }
    
    .pricing-subtitle {
      font-size: 16px; /* Smaller font size for mobile */
    }
    
    .pricing-card h3 {
      font-size: 1.25rem; /* Adjust size for mobile */
    }
    
    .pricing-card h2 {
      font-size: 2rem; /* Adjust size for mobile */
    }
    
    .pricing-card {
      margin: 10px 5px; /* Less margin for tighter mobile layout */
      padding: 15px; /* Adjust padding for mobile */
      border-top-width: 3px; /* Thinner top border for a more subtle effect */
    }
    
    .features-list li {
      font-size: 14px; /* Smaller font size for feature list items */
    }
    
    .card {
      background: #31333c; /* Ensuring card background matches design */
    }
  }
  
