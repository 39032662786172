.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px; /* Adjust padding as needed */
    height: 60px; /* Adjust height as needed */

      position: relative; /* Needed to apply z-index */
      z-index: 1000; /* Higher than the z-index of other elements */

    /* Other elements that should not overlap the navbar should have a lower z-index or none */
    

  }
  
  .navbar .logo {
    /* Add styles for your logo here */
    display: flex;
    align-items: center;
  }
  
  .navbar .logo img {
    max-width: 100px; /* Adjust based on the size of your logo */
  }
  
  .navbar .navbar-right {
    /* This will hold your buttons on the right */
    display: flex;
    align-items: center;
    gap: 20px; /* Adjust the gap between buttons as needed */
  }
  
  .navbar .navbar-right .sign-in {
    background: none;
    border: none;
    color: #fff; /* Default text color */
    cursor: pointer;
    transition: color 0.3s; /* Smooth transition for hover effect */
  }
  
  .navbar .navbar-right .sign-in:hover {
    color: #f0cc52; /* Text color on hover */
  }
  
  .navbar .navbar-right .get-started {
    background-color: #6366f1; /* Button background color */
    border: none;
    color: #fff; /* Default text color */
    cursor: pointer;
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 5px; /* Adjust border-radius as needed */
    display: inline-flex; /* Aligns text and icon */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    gap: 10px; /* Space between text and icon */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .navbar .navbar-right .get-started:hover {
    background-color: #4446e8; /* Background color on hover */
  }
  
  .navbar .navbar-right .get-started .bx {
    color: #f0cc52; /* Icon color */
    transition: color 0.3s, transform 0.3s; /* Add transform to the transition */
  }
  
  .navbar .navbar-right .get-started:hover .bx {
    color: #e7b925; /* Icon color on hover */
    transform: translateX(5px); /* Move icon 5px to the right on hover */
  }
  

/* Mobile specific styles */
@media (max-width: 768px) {
  .navbar {
    padding: 0 10px; /* Less padding on the sides */
    height: auto; /* Flexible height */
    flex-direction: column; /* Stack logo and navigation items vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .navbar .logo img {
    max-width: 80px; /* Smaller logo for mobile */
  }

  .navbar .navbar-right {
    flex-direction: column; /* Stack buttons vertically */
    align-items: flex-start; /* Align items to the start */
    gap: 10px; /* Less gap between buttons */
    width: 100%; /* Full width buttons */
    margin-top: 10px; /* Space after the logo */
  }

  .navbar .navbar-right .sign-in
  {
    display: none;
  }
  .navbar .navbar-right .get-started {
    width: 100%; /* Full width buttons */
    justify-content: flex-start; /* Align text to the start */
    padding: 10px; /* Adjust padding */
  }

  .navbar .navbar-right .get-started .bx {
    margin-left: auto; /* Push icon to the end of the button */
  }
}

